import React from 'react'
import {
  IcoImage,
  ImageColumn,
  ImageTitle,
} from '../CustomSingleMarkdown/styles'
import webImage from '../../../images/projects/ikona_web.svg'

import designImage from '../../../images/projects/ikona_design.svg'
import mobileImage from '../../../images/projects/ikona_mobile.svg'
import SEO from '../../Seo'

const CustomSingleProjectsMenu = (props) => {
  return (
    <>
    <SEO title={`${props.technology} portfolio`}/>
      <ImageColumn onClick={() => props.handleMenu('web')}>
        <IcoImage
          src={webImage}
          alt={'Web portfolio icon'}
          title={'Web portfolio switcher'}
          isActive={props.technology === 'web'}
        />
        <ImageTitle>web</ImageTitle>
      </ImageColumn>
      <ImageColumn onClick={() => props.handleMenu('mobile')}>
        <IcoImage
          src={mobileImage}
          alt={'Mobile portfolio icon'}
          title={'Mobile portfolio switcher'}
          isActive={props.technology === 'mobile'}
        />
        <ImageTitle>mobile</ImageTitle>
      </ImageColumn>
      <ImageColumn onClick={() => props.handleMenu('design')}>
        <IcoImage
          src={designImage}
          alt={'Design portfolio icon'}
          title={'Design portfolio switcher'}
          isActive={props.technology === 'design'}
        />
        <ImageTitle>design</ImageTitle>
      </ImageColumn>
    </>
  )
}

export default CustomSingleProjectsMenu
