import styled from 'styled-components'
import FlipMove from 'react-flip-move'
import {
  mediumBreakpoint,
  largeBreakpoint,
  fullhd,
} from '../../../config/css-constants'
import { fadeFromLeft } from '../../../assets/keyframes/keyframes'
import { Link } from 'gatsby'

export const ImageWrapper = styled.div`
  display: grid;
  grid-row-gap: 10px;
  @media all and (min-width: ${mediumBreakpoint}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    padding-top: 50px;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const ProjectImage = styled.img`
  cursor: pointer;
  height: 100%;
  max-height: 300px;
  max-width: 500px;
`

export const ProjectOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  opacity: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Section = styled.section`
  padding: 0 50px;
  @media all and (min-width: ${mediumBreakpoint}) {
    padding-left: 7rem;
    padding-right: 3rem;
    padding-top: 100px;
  }
`

export const HeaderWrapper = styled.div`
  display: grid;
  padding-bottom: 70px;
  @media all and (min-width: ${mediumBreakpoint}) {
    grid-template-columns: 1fr 2fr;
  }
`

export const HeaderImg = styled.img`
  display: none;
  @media all and (min-width: ${mediumBreakpoint}) {
    margin: 0 auto;
    transform: translateX(-100%);
    animation-fill-mode: forwards;
    animation-name: ${fadeFromLeft};
    animation-duration: 1.5s;
    animation-timing-function: ease-in;
    display: block;
    width: 300px;
    max-width: 100%;
    padding: 30px;
  }
`
export const HeaderMobileImgWrapper = styled.div`
  position: relative;
  padding: 70% 0 0;
  @media all and (min-width: ${mediumBreakpoint}) {
    display: none;
  }
`

export const HeaderMobileImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  @media all and (min-width: ${mediumBreakpoint}) {
    display: none;
  }
`

export const TextWrapper = styled.div`
  margin-bottom: 30px;
  @media all and (min-width: ${mediumBreakpoint}) {
    margin-bottom: 0;
  }
`

export const Title = styled.h2`
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
  @media all and (min-width: ${mediumBreakpoint}) {
    text-align: left;
    letter-spacing: 2px;
    font-size: 1.4rem;
  }
`

export const Description = styled.p`
  margin-bottom: 20px;
  @media all and (min-width: ${mediumBreakpoint}) {
    line-height: 2rem;
    font-size: 1rem;
    min-height: 128px;
    margin: 0;
  }
`

export const MenuWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  padding-top: 30px;
  @media all and (min-width: ${mediumBreakpoint}) {
    padding: 70px 0 30px 0;
    grid-column-gap: 30px;
  }
`

export const ImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  cursor: pointer;
  max-width: 150px;
  margin: 0 auto;
  @media all and (min-width: ${fullhd}) {
    max-width: unset;
  }
`

export const IcoImage = styled.img`
  width: 120px;
  max-width: 90%;
  border-radius: 50%;
  background-color: ${(props) => (props.isActive ? '#ffc34a' : 'unset')};
  border: ${(props) => (props.isActive ? '' : '1px solid green')};

  @media all and (min-width: 1000px) {
    width: 150px;
  }

  &:hover {
    border: 1px solid #ffc34a;
    background-color: #ffc34a;
  }
`

export const ImageTitle = styled.p`
  color: #47a6b5;
  @media all and (min-width: ${mediumBreakpoint}) {
    padding-top: 20px;
  }
`

export const ProjectsTransition = styled(FlipMove)`
  display: flex;
  flex-wrap: wrap;
  @media all and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
  @media all and (min-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const ProjectLinkWrapper = styled.div`
  width: 100%;
  padding: 60% 0 0;
  position: relative;
  margin: 10px 0;
  flex-grow: 1;
  @media all and (min-width: ${mediumBreakpoint}) {
    width: calc(50% - 10px);
    padding: 35% 0 0;
    &:nth-of-type(2n + 1) {
      margin-right: 5px;
    }
    &:nth-of-type(2n + 2) {
      margin-left: 5px;
    }
  }
  @media all and (min-width: ${largeBreakpoint}) {
    width: 30%;
    padding: 24% 0 0;
    margin: 10px;
    &:nth-of-type(3n + 1) {
      margin: 10px 10px 10px 0;
    }
    &:nth-of-type(3n + 3) {
      margin: 10px 0 10px 10px;
    }
  }
`

export const ProjectLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.background};

  &:hover ${ProjectOverlay} {
    opacity: 1;
    background: rgba(0, 0, 0, 0.8);
    transition: all 0.4s ease;
  }

  picture {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`

export const ProjectsWrapper = styled.div`
  position: relative;
`
