import styled from 'styled-components'
import { largeBreakpoint, mediumBreakpoint } from '../../config/css-constants'
import { fadeFromLeft, fadeFromRight } from '../../assets/keyframes/keyframes'

export const AnimatedImageWrapper = styled.div`
  min-height: 20px;
  display: block;
  width: 100%;
  height: 100%;
`

export const Section = styled.section`
  overflow: hidden;
  position: relative;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  @media all and (min-width: ${mediumBreakpoint}) {
    padding: 200px 0;
  }
`

export const TextWrapper = styled.div`
  margin-top: 10px;
  @media all and (min-width: ${mediumBreakpoint}) {
    margin-top: 0;
  }
`

export const Title = styled.h2`
  font-weight: bold;
  padding-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  @media all and (min-width: ${mediumBreakpoint}) {
    font-weight: 400;
    padding: 0;
    text-align: left;
    font-size: 1.8rem;
  }
`

export const Description = styled.p`
  padding-top: 10px;
  font-weight: 500;
  line-height: 1rem;
  font-size: 0.9rem;
  margin-top: 170px;
  @media all and (min-width: ${mediumBreakpoint}) {
    margin-top: 20px;
    padding: 0;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 400;
  }
`

export const ImageWrapper = styled.div`
  text-align: center;
  position: absolute;
  height: 180px;
  width: 150px;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  @media all and (min-width: ${mediumBreakpoint}) {
    position: static;
    transform: unset;
    width: 100%;
    max-height: unset;
    max-width: 300px;
    margin: 0 auto;
  }
`

export const Image = styled.img`
  margin: 20px auto 0;
  max-height: 150px;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-name: ${(props) =>
      props.isvisible && props.animation === 'left' ? fadeFromLeft : ''}
    ${(props) =>
      props.isvisible && props.animation === 'right' ? fadeFromRight : ''};
  animation-duration: 1s;
  animation-timing-function: ease-in;
  transform: ${(props) =>
      props.rotateOnMobile ? `rotate(${props.rotateOnMobile})` : 'unset'}
    ${(props) =>
      props.animation === 'left' ? `translateX(-100%)` : 'translate(100%)'};
  @media all and (min-width: ${mediumBreakpoint}) {
    transform: rotate(0deg)
      ${(props) =>
        props.animation === 'left' ? `translateX(-100%)` : 'translate(100%)'};
    height: 300px;
    min-height: 200px;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    min-height: 300px;
  }
`
