import React from 'react'
import { graphql } from 'gatsby'

import CustomSingleMarkdown from '../../components/CustomProjects/CustomSingleMarkdown/CustomSingleMarkdown'
import {
  InterestedSection,
  InterestedHeader,
  InterestedHeaderAnswer,
  Section,
} from '../../components/ReusableComponents/ContactUsLinkStyles'
import { ButtonLink } from '../../components/UI/button'

const ProjectsLayout = ({ data }) => {
  const { edges } = data.allMarkdownRemark

  return (
    <>
      <CustomSingleMarkdown data={edges} />
      <Section>
        <InterestedSection>
          <InterestedHeader>
            <InterestedHeaderAnswer>
              Please contact us if you have any questions
            </InterestedHeaderAnswer>
          </InterestedHeader>
          <ButtonLink to="/contact">
            <span>Contact Us!</span>
          </ButtonLink>
        </InterestedSection>
      </Section>
    </>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(filter: { fields: { collection: { eq: "pages" } } }) {
      edges {
        node {
          fields {
            collection
          }
          frontmatter {
            title
            path
            date
            imageName
            description
            path
            technologies
          }
        }
      }
    }
  }
`

export default ProjectsLayout
