import styled from 'styled-components'
import { keyframes } from 'styled-components'
import { largeBreakpoint } from '../../config/css-constants'

const spin = keyframes`
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
`

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: -70px;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  @media all and (min-width: ${largeBreakpoint}) {
    display: block;
    top: -90px;
  }
`

export const SpinnerCircle = styled.div`
  border: 8px solid #f2f2f2; 
  border-top: 8px solid #357E8D; 
  border-right: 8px solid #357E8D;
  border-bottom: 8px solid #357E8D;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1.5s linear infinite;
  margin: 0 auto;
  @media all and (min-width: ${largeBreakpoint}){
    left: 50%;
    top: 150px;
  })
`
