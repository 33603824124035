import React, { Component } from 'react'
import CustomSingleProjectsMenu from '../CustomSingleProjectsMenu/CustomSingleProjectsMenu'
import Spinner from '../../../components/ReusableComponents/Spinner'
import {
  ProjectImage,
  ProjectOverlay,
  HeaderImg,
  HeaderMobileImg,
  HeaderMobileImgWrapper,
  HeaderWrapper,
  MenuWrapper,
  Section,
  ProjectLink,
  ProjectsTransition,
  ProjectsWrapper,
  ProjectLinkWrapper,
  Description,
} from './styles'

import designerImage from '../../../images/projects/projektant.svg'
import designerMobileImage from '../../../images/projects/projektant_mobile.svg'
import { TextWrapper } from '../../CustomTechnologies/customElements'
import { Title } from '../../CustomHowWeWork/WorkReusableCode'
import SEO from '../../Seo'

class CustomSingleMarkdown extends Component {
  state = {
    projects: this.props.data,
    projectsLoading: false,
    tech: '',
  }

  handleProjectsChange = (technology) => {
    const elementTop = this.projectsSection.offsetTop

    this.setState({
      projectsLoading: true,
      tech: technology,
    })

    const oldProjects = this.props.data
    const newProjects = [...oldProjects].filter((project) => {
      return project.node.frontmatter.technologies.includes(technology)
    })

    setTimeout(() => {
      this.setState({
        projectsLoading: false,
        projects: newProjects,
      })
      window.scrollTo({
        behavior: 'smooth',
        top: elementTop - 100,
      })
    }, 500)
  }

  render() {
    const projects = this.state.projects.map((project) => {
      const title = project.node.frontmatter.title
      const imageSrc = project.node.frontmatter.imageName
      const urlToProject = project.node.frontmatter.path
      const colors = ['#4DA274', '#2D7080', '#C44151', '#F9C02D', '#46A0A0']
      let randomBackground = colors[Math.floor(colors.length * Math.random())]

      return (
        <ProjectLinkWrapper key={title}>
          <ProjectLink
            background={randomBackground}
            to={`/portfolio${urlToProject}`}
          >
            <picture>
              <source
                srcSet={require(`../../../images/projects/${imageSrc}.webp`)}
                type="image/webp"
              />
              <source
                srcSet={require(`../../../images/projects/${imageSrc}.png`)}
                type="image/jpeg"
              />
              <ProjectImage
                src={require(`../../../images/projects/${imageSrc}.png`)}
                alt={title}
                title={title}
              />
            </picture>
            <ProjectOverlay>
              <p>{title}</p>
            </ProjectOverlay>
          </ProjectLink>
        </ProjectLinkWrapper>
      )
    })
    return (
      <>
        <Section className={'container is-fluid custom_container'}>
          <SEO title="Portfolio"/>
          <HeaderWrapper>
            <div>
              <HeaderImg
                src={designerImage}
                alt={''}
                title={'man with realized projects'}
              />
              <HeaderMobileImgWrapper>
                <HeaderMobileImg
                  src={designerMobileImage}
                  alt={''}
                  title={'man with realized projects'}
                />
              </HeaderMobileImgWrapper>
            </div>
            <TextWrapper>
              <Title>Projects</Title>
              <Description>
                Since we were founded in 2011, we have created web applications,
                desktop applications and mobile apps. Our team specialises both
                in building projects from scratch and working with inherited,
                legacy codebases. We effectively converge old technologies into
                new ones, so your business is secured and up to date with the
                newest technological trends and standards. Have a look at some
                cases we have worked on with our customers.
              </Description>
              <MenuWrapper>
                <CustomSingleProjectsMenu
                  handleMenu={this.handleProjectsChange}
                  technology={this.state.tech}
                />
              </MenuWrapper>
            </TextWrapper>
          </HeaderWrapper>
        </Section>
        <ProjectsWrapper ref={(elem) => (this.projectsSection = elem)}>
          {this.state.projectsLoading && <Spinner />}
          <ProjectsTransition duration={500} enterAnimation="fade">
            {projects}
          </ProjectsTransition>
        </ProjectsWrapper>
      </>
    )
  }
}

export default CustomSingleMarkdown
